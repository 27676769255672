import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';import { LayoutComponent } from '../layouts/layout/layout.component';
import { PublicCollecteComponent } from './public-collecte/public-collecte.component';
import { PriveCollecteComponent } from './prive-collecte/prive-collecte.component';
import { ContributionComponent } from './contribution/contribution.component';
import { UserListeComponent } from './user-liste/user-liste.component';
;

const routes: Routes = [
    { path: 'dashboard', component: DashboardComponent }, 
    { path: 'collecte/public', component: PublicCollecteComponent }, 
    { path: 'collecte/privee', component: PriveCollecteComponent }, 
    { path: 'contribution', component: ContributionComponent }, 
    { path: 'utilisateurs', component: UserListeComponent }, 
    { path: 'pages', loadChildren: () => import('./utility/utility.module').then(m => m.UtilityModule) },
    { path: 'ui', loadChildren: () => import('./ui/ui.module').then(m => m.UIModule) },
    { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
