<!-- Section: Design Block -->
<section class="background-radial-gradient overflow-hidden d-flex align-items-center justify-content-center" style="min-height: 100vh;">
  <div class="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
    <div class="row gx-lg-5 align-items-center mb-5">
      <div class="col-lg-6 mb-5 mb-lg-0" style="z-index: 10">
        <h1 class="my-5 display-5 fw-bold ls-tight" style="color: hsl(218, 81%, 95%)">
          Espace<br />
          <span style="color: hsl(218, 81%, 75%)"> privé Be cca </span>
        </h1>
        <!-- <p class="mb-4 opacity-70" style="color: white">
          Transformez Chaque Contribution en Réalités, Avec Becca, collectez des 
          fonds facilement pour associations, événements, et projets personnels.
        </p> -->
      </div>

      <div class="col-md-6 mb-5 mb-lg-0 position-relative">
        <div id="radius-shape-1" class="position-absolute rounded-circle shadow-5-strong"></div>
        <div id="radius-shape-2" class="position-absolute shadow-5-strong"></div>

        <div class="card bg-glass">
          <div class="card-body px-4 py-5 px-md-5">
            <div class="d-flex justify-content-center">
              <img src="../../../../assets/images/becca.png" alt="" width="130px" srcset="">
  
            </div>
            <form>
              <!-- Email input -->
              <div data-mdb-input-init class="form-outline mb-4">
                <label class="form-label" for="form3Example3">Adresse Email</label>
                <input type="email" id="form3Example3" class="form-control" />
              </div>

              <!-- Password input -->
              <div data-mdb-input-init class="form-outline mb-4">
                <label class="form-label" for="form3Example4">Mot de passe</label>
                <input type="password" id="form3Example4" class="form-control" />
              </div>

              <!-- Submit button -->
              <button style="background-color: #672A90;" routerLink="/page/dashboard" type="submit" data-mdb-button-init data-mdb-ripple-init class="btn btn-primary btn-block mb-4">
                Connexion
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Section: Design Block -->
