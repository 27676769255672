import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LocalStorageService } from '../storage/localstorage.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})

export class ParameterService {


  constructor(private http: HttpClient) {

  }

//collecte prive
  getCollectePrive(item:any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'collectes-private'}`,item);
  }

  addProjet(item: any): Observable<any> {
    //console.log("webservice");

    return this.http.post(environment.apiUrl + 'operation/projet', item);
  }
  editProjet(id: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/change-projet/${id}`, item);
  }

  deleteProjet(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/projet/${id}`);
  }
  //end
//collecte public

  getCollectePublic(item:any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'collectes-public'}`,item);
  }
  //user
getUserNonVerife(item:any): Observable<any> {
  return this.http.post(`${environment.apiUrl + 'users-not-verified'}`,item);
}

//validate user

validateUser(est_valide: any ,reference:any): Observable<any> {

  const requestData = {
    "user_id" :reference,
    "est_valide":est_valide
  }
  return this.http.post(environment.apiUrl + `users-validation`,requestData);
}
//end

}



//end

