import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'TABLEAUX DE BORD',
        icon: 'ri-dashboard-line',
        badge: {
            variant: 'success',
            text: '',
        },
        link: 'dashboard'
    },
 
    {
        id: 5,
        label: 'Utilisateurs',
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 6,
                label: 'Liste des utilisateurs',
                link: '/page/utilisateurs',
                parentId: 5
            },
          
         
        ]
    },
    {
        id: 13,
        label: 'Contributions',
        icon: 'ri-mail-send-line',
        subItems: [
            {
                id: 14,
                label: 'Liste des contributions',
                link: '/page/contribution',
                parentId: 14
            },
           
        ]
    },
    
    {
        id: 23,
        label: 'Collectes',
        icon: 'ri-profile-line',
        subItems: [
            {
                id: 24,
                label: 'Public',
                link: '/page/collecte/public',
                parentId: 23
            },

            {
                id: 24,
                label: 'Privée',
                link: '/page/collecte/privee',
                parentId: 23
            },
           
           
           
        ]
    },

 
    // {
    //     id: 18,
    //     label: 'MENUITEMS.AUTHENTICATION.TEXT',
    //     icon: 'ri-account-circle-line',
    //     subItems: [
    //         {
    //             id: 19,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
    //             link: '/pages/login-1',
    //             parentId: 18
    //         },
    //         {
    //             id: 20,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER',
    //             link: '/pages/register-1',
    //             parentId: 18
    //         },
    //         {
    //             id: 21,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD',
    //             link: '/pages/recoverpwd-1',
    //             parentId: 18
    //         },
    //         {
    //             id: 22,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
    //             link: '/pages/lock-screen-1',
    //             parentId: 18
    //         }
    //     ]
    // },
  
   
  
];
