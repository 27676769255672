<div class="container-fluid">
  <app-pagetitle title="Tableaux de bord" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-xl-3">
      <div class="row">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <div class="flex-1 overflow-hidden">
                <p class="text-truncate font-size-14 mb-2">Utilisateurs</p>
                <h4 class="mb-0">100</h4>
              </div>
              <div class="text-primary ms-auto">
                <i class="pi pi-user" style="font-size: 0.8rem"></i>
              </div>
            </div>
          </div>
        
        </div>
        
      </div>
      <!-- end row -->


    </div>

    <div class="col-xl-3">
      <div class="row">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <div class="flex-1 overflow-hidden">
                <p class="text-truncate font-size-14 mb-2">Contributions</p>
                <h4 class="mb-0">100</h4>
              </div>
              <div class="text-primary ms-auto">
                <i class="pi pi-briefcase" style="font-size: 0.8rem"></i>
              </div>
            </div>
          </div>
        
        </div>
        
      </div>
      <!-- end row -->


    </div>


    <div class="col-xl-3">
      <div class="row">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <div class="flex-1 overflow-hidden">
                <p class="text-truncate font-size-14 mb-2">Collecte</p>
                <h4 class="mb-0">100</h4>
              </div>
              <div class="text-primary ms-auto">
                <i class="pi pi-bullseye " style="font-size: 0.8rem"></i>
              </div>
            </div>
          </div>
        
        </div>
        
      </div>
      <!-- end row -->


    </div>


   
  </div>
 
</div>