<div class="container-fluid">

  <h4 id="upper"> collecte  Privées</h4>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>
            <!--
            <a href="javascript:void(0);" class="btn btn-success mb-2" id="gras" (click)="openModal()">
              <i class="mdi mdi-plus me-2"></i> Ajouter une collecte
            </a>  -->
          </div>
          <div class="col-md-12 d-flex justify-content-end">
            <div id="tickets-table_filter" class="dataTables_filter text-md-end">
              <label class="d-inline-flex align-items-center">
                Rechercher:
                <input type="text" name="searchTerm" [(ngModel)]="searchTerm"  class="form-control form-control-sm ms-2" aria-controls="tickets-table">
              </label>
            </div>
          </div>
        
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light  rwd-table">
                <tr>
                  <th style="width: 20px;">
                  </th>
                  <th>Titre</th>
                  <th>Catégorie </th>
                  <th>Motif</th>
                  <th style="white-space: nowrap;">Date écheance</th>
                  <th>Date création</th>
                  <th>Montant visé</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of filteredProjetData ; let index = index">
                  <td>
                  </td>
                  <td>{{item?.titre}}</td>
                  <td>{{item?.categorie_cagnotte?.libelle}}</td>
                  <td>{{item?.motif}}</td>

                  <td>{{item?.date_echeance}}</td>
                  <td>{{item?.date_creation}}</td>
                  <td>{{item?.montant_vise}}</td>
                  <td>
                    <a (click)="validataCollecte(item)" style="cursor: pointer;" class="me-3 text-primary" v-b-tooltip.hover
                      title="valider">
                      <i class="pi pi-check-square" style="font-size: 1rem;" ></i>
                  </a>

                    <a (click)="confirmDeletion(item)" href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                      title="Retirer">
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>

                <tr *ngIf="iTemsData.length === 0 && isLoading === false">
                  <td colspan="8">
                    <div class="row">
                      <div class="text-center">
                        <h5 id="graycolor">Aucune donnée trouvée</h5>
                      </div>
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

          <div class="d-flex justify-content-center" *ngIf="isLoading === true">
            <div class="container">
              <div class="row">
                <div class="text-center">
                  <h5 id="graycolor">Chargement en cours</h5>
                  <div class="loader4"></div>
                </div>
              </div>
            </div>
          </div>

          <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets" [rows]="rowsProjets"
            [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
          </p-paginator>
        </div>
      </div>

    </div>

  </div>
</div>

<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>