import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiModule } from '../shared/ui/ui.module';
import { WidgetModule } from '../shared/widget/widget.module';

import { PagesRoutingModule } from './pages-routing.module';

import { SimplebarAngularModule } from 'simplebar-angular';
import { NgbNavModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgPipesModule } from 'ngx-pipes';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DndModule } from 'ngx-drag-drop';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { DashboardComponent } from './dashboard/dashboard.component';

import { UIModule } from './ui/ui.module';
import { IconsModule } from './icons/icons.module';
import { PaginatorModule } from 'primeng/paginator';
import { PublicCollecteComponent } from './public-collecte/public-collecte.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PriveCollecteComponent } from './prive-collecte/prive-collecte.component';
import { ToastModule } from 'primeng/toast';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ContributionComponent } from './contribution/contribution.component';
import { UserListeComponent } from './user-liste/user-liste.component';



@NgModule({
  declarations: [DashboardComponent, PublicCollecteComponent,PriveCollecteComponent,ContributionComponent,UserListeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    UIModule,
    NgPipesModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgApexchartsModule,
    DndModule,
    FullCalendarModule,
    IconsModule,
    LeafletModule,
    WidgetModule,
    SimplebarAngularModule,
    PaginatorModule,
    ConfirmDialogModule,
    ToastModule,
    NgxUiLoaderModule,
  ],
  providers: [
  ]
})
export class PagesModule { }
