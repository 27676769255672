import { Component } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
@Component({
  selector: 'app-prive-collecte',

  templateUrl: './prive-collecte.component.html',
  styleUrl: './prive-collecte.component.scss'
})

export class PriveCollecteComponent {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  iTemsData: any = [];
  modalRef: NgbModalRef | null = null;
  isLoading: boolean = false;
  searchTerm: string = '';
  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  filteredtems: any = [];
  paginatedProjets = [];
  filteredProjetData: any[] = []; // Ajout d'une liste pour les données filtrées

  pageSize: number = 10;

  constructor(
    private modalService: NgbModal,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {
  }

  ngOnInit(): void {

    this.getCollectePrive();
  }

  getCollectePrive() {
    this.isLoading = true;
    this.paramservice.getCollectePrive("").subscribe(
      (response: any) => {
        this.isLoading = false;
        this.iTemsData = response.data;
        this.filteredProjetData = [...this.iTemsData]; // Initialiser avec toutes les données
        this.totalRecords = this.iTemsData.length;
        this.updatePaginatedProjets(); // Initial pagination
      },
      (error) => {
        this.isLoading = false;
        console.log("une erreur est survenue", error);
      }
    );
  }
  onPageChangeProjets(event) {
    this.firstProjets = event.page * this.rowsProjets; // Mise à jour de la page actuelle
    this.updatePaginatedProjets();
  }

  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.iTemsData.slice(start, end);
  }

  openModal() {
    // this.modalService.open(AddProjetComponent, {
    //   centered: true,
    //   backdrop: 'static',
    //   size: 'xl'
    // });
  }


  DetailModal(projetIndicateur: any) {
    this.localstorage.saveProjet(projetIndicateur)
    this.router.navigate(['/suivi/plan/detail-projet'])
  }

  //
  validataCollecte(project: any) {
    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de vouloir valider cette collecte :' + project.titre + '?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'p-button-text',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.ngxService.start();
        this.paramservice.deleteProjet(project.reference).subscribe(
          (response: any) => {
            this.ngxService.stop();
            console.log("la reponse du webserice", response);
            this.messageService.add({ severity: 'info', summary: "Bécca", detail: response.message });
            this.getCollectePrive();
          },
          (error) => {
            this.ngxService.stop();
            console.log("une erreur est survenu", error);
            this.messageService.add({ severity: 'error', summary: "Bécca", detail: "Une erreur est survenue. Veuillez réessayé" });
          },
        )
      },
      reject: () => {
        //this.messageService.add({ severity: 'warn', summary: '', detail: 'Vous avez rejeté', life: 3000 });
      }
    });
  }
  //end
  //methode de suppression
  confirmDeletion(project: any) {
    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer ce projet ' + project.libelle + '?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'p-button-text',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.ngxService.start();
        this.paramservice.deleteProjet(project.reference).subscribe(
          (response: any) => {
            this.ngxService.stop();
            console.log("la reponse du webserice", response);
            this.messageService.add({ severity: 'info', summary: "Bécca", detail: response.message });
            this.getCollectePrive();
          },
          (error) => {
            this.ngxService.stop();
            console.log("une erreur est survenu", error);
            this.messageService.add({ severity: 'error', summary: "Bécca", detail: "Une erreur est survenue. Veuillez réessayé" });
          },
        )
      },
      reject: () => {
        //this.messageService.add({ severity: 'warn', summary: '', detail: 'Vous avez rejeté', life: 3000 });
      }
    });
  }




  changePageSize() {
    // Met à jour les données filtrées en fonction de la nouvelle taille de page sélectionnée
    this.filteredProjetData = this.filteredProjetData.slice(0, this.pageSize);
  }
}